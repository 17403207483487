import {
    useMantineTheme, Button, Flex, Center, Text, Modal, LoadingOverlay
} from '@mantine/core';

export default function ConfirmationModal({opened, onClose, text, title = 'Confirmation', onClick, approveBtnText = 'Confirm' , isLoading = false}) {
    const theme = useMantineTheme();

  return (
        <Modal
            centered
            opened={opened}
            onClose={onClose}
            withCloseButton={false}
            title={title}
            style={{ zIndex: 999 }}
            padding={'xl'}
        >
            <LoadingOverlay visible={isLoading} overlayBlur={2} radius='lg'/>
            <Center>
                <Text size='lg' ta="center" weight={600} color={theme.colors.gray[6]}>{text}</Text>
            </Center>
            <Flex justify="flex-start"
                    align="center"
                    direction="row"
                    wrap="wrap" 
                    gap="md" mt={'xl'}>
                <Button color='gray' sx={{flex: 1}} onClick={onClose} variant='light'>
                    Cancel
                </Button>
                <Button sx={{flex: 1}} onClick={onClick} variant='light'>
                    {approveBtnText}
                </Button>
            </Flex>
        </Modal>
  )
}
