import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

import Unauthorized from "../../pages/Misc/Unauthorized";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();

    return (
        allowedRoles?.includes(auth?.role)
            ? <Outlet />
        : auth?.admin_id
            ? <Unauthorized />
            : <Navigate to="/login" state={{ from: location }} replace />
    )
}

export default RequireAuth;