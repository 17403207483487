import axios from "../api/axios"
import useAuth from "./useAuth"

 export default function useRefreshToken() {
    const { setAuth } = useAuth()

    const refresh = async () => {
        try{
            const res = await axios.post('/auth/generate-token', {withCredentials: true});
            
            const accessToken = res?.data?.token;
            const userDetails = res?.data?.userDetails;
            setAuth({ fullname: userDetails?.fullname, uid: userDetails?.uid, company: userDetails?.company, role: userDetails?.role, accessToken})
            
            return accessToken
        }
        catch(error){
            setAuth(null)
            return null;
        }
    }
   return refresh
 }
 