import { useEffect, useState } from 'react';
import {
    Button, Flex, TextInput, Modal, Stack, Textarea, FileInput, Select,
} from '@mantine/core';
import { useForm } from '@mantine/form';

export default function CreateTicketModal({ opened, onClose, onSubmit, isSuccess, category }) {
    const [file, setFile] = useState(null);

    const form = useForm({
        initialValues: { title: '', message: '', category_id: '', severity: ''},

        validate: {
            title: (value) => (value.length < 1 ? 'Please fill in the empty field' : null),
            message: (value) => (value.length < 1 ? 'Please fill in the empty field' : null),
            category_id: (value) => (value.length < 1 ? 'Please select category' : null),
            severity: (value) => (value.length < 1 ? 'Please select severity' : null),
        },
    });

    useEffect(() => {
        if (isSuccess || opened) {
            form.reset()
            setFile(null)
        }
    }, [isSuccess, opened]);


    return (
        <Modal
            centered
            opened={opened}
            onClose={onClose}
            title={'Create Ticket'}
            style={{ zIndex: 999 }}
            padding={'xl'}
        >
            <form onSubmit={form.onSubmit((values) => onSubmit({ ...values, file }))} autoComplete="off">
                <Stack>
                    <Select
                        label="Category"
                        data={category}
                        {...form.getInputProps('category_id')}
                    />
                    <TextInput
                        label="Title"
                        {...form.getInputProps('title')}
                    />
                    <Textarea
                        label="Message"
                        {...form.getInputProps('message')}
                        minRows={4}
                    />
                    <Select
                        label="Severity"
                        data={[
                            { value: '1', label: '1 - Cosmetic' },
                            { value: '2', label: '2 - Low' },
                            { value: '3', label: '3 - Medium' },
                            { value: '4', label: '4 - High' },
                            { value: '5', label: '5 - Critical' },
                        ]}
                        {...form.getInputProps('severity')}
                    />
                    {/*
                        Critical: Issues classified as critical have a severe impact on operations, causing a complete loss of service or functionality. These issues often require immediate attention and urgent resolution.
                        High: High severity issues have a significant impact on operations but may not completely halt services. They could severely degrade performance or cause major functionality issues, affecting a large number of users or critical systems.
                        Medium: Medium severity issues have noticeable but not critical impacts on operations. They may affect specific features or functionalities, causing inconvenience to users or impacting productivity to a moderate extent.
                        Low: Low severity issues have minimal impact on operations and are typically minor inconveniences or cosmetic issues. They do not significantly affect functionality or performance but may still require attention over time.
                        Cosmetic: Cosmetic severity issues are strictly visual or superficial in nature, having no impact on functionality or performance. They are typically related to UI/UX elements, design inconsistencies, or minor formatting issues.
                    */}
                    <FileInput
                        label="Image"
                        description="*optional"
                        placeholder="Choose image"
                        accept="image/png,image/jpeg"
                        multiple={false}
                        value={file} onChange={setFile}
                    />
                </Stack>
                <Flex justify="flex-start"
                    align="center"
                    direction="row"
                    wrap="wrap"
                    gap="md" mt={'xl'}>
                    <Button color='gray' sx={{ flex: 1 }} onClick={() => { onClose(); }} variant='light'>
                        Cancel
                    </Button>
                    <Button type="submit" sx={{ flex: 1 }} variant='light'>
                        Submit
                    </Button>
                </Flex>
            </form>
        </Modal>
    )
}