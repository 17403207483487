import { createStyles, Navbar, ScrollArea, Text } from '@mantine/core';
import {
  IconUsers,
  IconCheckupList,
  IconUsersGroup,
  IconUser
} from '@tabler/icons-react';
import { Link, useLocation } from 'react-router-dom'
import { useTimeout } from '@mantine/hooks'


import { ROLE_ADMIN } from '../../utils/Constants';
import useAuth from '../../hooks/useAuth';

const useStyles = createStyles((theme) => {

  return {
    navbar: {
        backgroundColor: theme.colorScheme === 'dark' && theme.colors.dark[8],
    },
    header: {
        paddingBottom: theme.spacing.md,
        marginBottom: theme.spacing.md * 1.5,
        borderBottom: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
        }`,
    },

    footer: {
        paddingTop: theme.spacing.md,
        marginTop: theme.spacing.md,
        borderTop: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
        }`,
    },

    link: {
        ...theme.fn.focusStyles(),
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        fontSize: theme.fontSizes.sm,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
        padding: `${8}px ${10}px`,
        fontWeight: 500,
        cursor: 'pointer',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 1,
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4],
        

        '&:hover': {
            // backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],
            color: theme.colorScheme === 'dark' ? theme.white : theme.black,
            borderColor: theme.colorScheme === 'dark' ? theme.white : theme.black,
        },
    },

    linkIcon: {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
        marginRight: theme.spacing.sm,
    },

    linkActive: {
        '&, &:hover': {
            backgroundColor: theme.fn.variant({ variant: theme.colorScheme === 'dark' ? 'light' : 'light', color: theme.primaryColor })
            .background,
            color: theme.fn.variant({ variant: theme.colorScheme === 'dark' ? 'light' : 'light', color: theme.primaryColor }).color,
            // borderColor: theme.colors.iCourtOrange[6],
        },
    },

    linkDivider: {
        marginTop: theme.spacing.md,
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 1,
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4],
        paddingLeft: theme.spacing.sm,
        paddingTop: 2,
        paddingBottom: 2,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    }
  };
});


const admin_nav = [
  { link: '/', links: ['/', '/chronology'], label: 'Manage Tickets', icon: IconCheckupList, divider: true, dividerLabel: 'Tickets' },
  { link: '/client', links: ['/client'], label: 'Manage Client', icon: IconUsersGroup, divider: true, dividerLabel: 'Users' },
  { link: '/developer', links: ['/developer'], label: 'Manage Developer', icon: IconUsers, },
  { link: '/admin', links: ['/admin'], label: 'Manage Admin', icon: IconUser, },
];

const developer_nav = [
    { link: '/', links: ['/', '/chronology'], label: 'Manage Tickets', icon: IconCheckupList, divider: true, dividerLabel: 'Tickets' },
];

export default function SideNavbar({opened, setOpened}) {
  const location = useLocation();
  const { classes, cx, theme } = useStyles();
  const { start, clear } = useTimeout(() => setOpened(false), 100);
  const { auth } = useAuth();

    var links = null
    if(auth?.role === ROLE_ADMIN){
        links = admin_nav.map((item, index) => (
            <div key={`${item.label}-${index}`}>
                {
                item.divider ? 
                    <div className={classes.linkDivider}>
                        <Text size={12} color={theme.colorScheme === 'dark' ? 'gray.4' : 'gray.8'}>{item?.dividerLabel}</Text>
                    </div>
                :   null
                }
                <Link
                    className={cx(classes.link, { [classes.linkActive]: item.links?.includes(`/${location.pathname.split("/")[1]}`)})}
                    onClick={() => {clear(); start();}}
                    to={item.link}
                >
                    <item.icon  size={20}/>
                    <span style={{whiteSpace: 'nowrap', marginLeft: 10}}>{item.label}</span>
                </Link>
            </div>
        ));
    }
    else{
        links = developer_nav.map((item, index) => (
            <div key={`${item.label}-${index}`}>
                {
                item.divider ? 
                    <div className={classes.linkDivider}>
                        <Text size={12} color={theme.colorScheme === 'dark' ? 'gray.4' : 'gray.8'}>{item?.dividerLabel}</Text>
                    </div>
                :   null
                }
                <Link
                    className={cx(classes.link, { [classes.linkActive]: item.link === `/${location.pathname.split("/")[1]}` })}
                    onClick={() => {clear(); start();}}
                    to={item.link}
                >
                    <item.icon  size={20}/>
                    <span style={{whiteSpace: 'nowrap', marginLeft: 10}}>{item.label}</span>
                </Link>
            </div>
        ));
    }

  return (
      <Navbar className={classes.navbar} hiddenBreakpoint="sm" width={{ base: !opened ? 1 : 0, sm: 220, lg: 300, }}
        sx={{
          overflow: "hidden",
          transition: "width 500ms ease, min-width 500ms ease",
        }} >
        <ScrollArea >
            <Navbar.Section pr="sm" pl="sm" pb="md" grow>
                {links}
            </Navbar.Section>
        </ScrollArea>
      </Navbar>
  );
}