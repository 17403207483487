import React from 'react'
import { Group, Text, Divider, Button, Badge } from '@mantine/core'
import { useState } from 'react'
import { IconTicket, IconCheckbox, IconCheck, IconExclamationCircle, IconEye, IconInfoCircle, } from '@tabler/icons-react'
import { DataGrid } from 'mantine-data-grid';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { notifications } from '@mantine/notifications';

import ViewTicketModal from '../../components/Tickets/ViewTicketModal';
import CreateTicketModal from '../../components/Tickets/CreateTicketModal';
import UpdateTicketStatusModal from '../../components/Tickets/UpdateTicketStatusModal';
import InfoModal from '../../components/Tickets/InfoModal';

import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

import { ROLE_CLIENT } from '../../utils/Constants';

import { formatDD_MM_YYYY_hh_mm_A } from '../../utils/DateTime';
import { Link } from 'react-router-dom';

export default function Tickets() {
    const axiosPrivate = useAxiosPrivate();
    const queryClient = useQueryClient();
    const { auth } = useAuth()

    const [openViewModal, setOpenViewModal] = useState(false)
    const [openCreateModal, setOpenCreateModal] = useState(false)
    const [openUpdateModal, setOpenUpdateModal] = useState(false)
    const [openInfoModal, setOpenInfoModal] = useState(false)
    // const [openDeleteModal, setOpenDeleteModal] = useState(false)
    // const [openEditModal, setOpenEditModal] = useState(false)

    const [selectedRow, setSelectedRow] = useState(null)

    const { isLoading, isFetching, isError, error, data, } = useQuery(
        "tickets",
        async ({ signal }) => (await axiosPrivate.post(`${auth?.role === ROLE_CLIENT ? '/tickets/read-by-uid' : '/tickets/read-all'}`, {uid: auth?.uid}, { signal })).data.data,
        {
            initialData: [],
            onError: () => {
                notifications.show({
                    id: 'error',
                    title: `Error`,
                    message: `Unable to load data`,
                })
            }
        },
    );

    // category/read-by-uid-selection

    const categoryQuery = useQuery(
        "category",
        async ({ signal }) => (await axiosPrivate.post('/category/read-by-uid-selection', {uid: auth?.uid}, { signal })).data.category,
        {
            enabled: auth?.role === ROLE_CLIENT,
            initialData: [],
            onSuccess: (res) => {
                console.log(res)
            },
            onError: () => {
                // notifications.show({
                //     id: 'error',
                //     title: `Error`,
                //     message: `Unable to load data`,
                // })
            }
        },
    );

    const createTicketMutation = useMutation(
        (data) => axiosPrivate.post(`/tickets/create`, data, { headers: { 'Content-Type': 'multipart/form-data' } }),
        {
            onSuccess: () => {
                notifications.update({
                    id: 'create-ticket',
                    color: 'teal',
                    title: 'Ticket created',
                    message: 'The ticket is successfully created.',
                    icon: <IconCheck size={16} />,
                    autoClose: 4000,
                });
                queryClient.invalidateQueries("tickets")
            },
            onError: () => {
                notifications.update({
                    id: 'create-ticket',
                    color: 'red',
                    title: 'Error',
                    message: 'An error has occurred.',
                    icon: <IconExclamationCircle size={16} />,
                    autoClose: 4000,
                });
            }
        }
    );

    
    const updateTicketMutation = useMutation(
        (data) => axiosPrivate.post(`/tickets/update-status`, data, { headers: { 'Content-Type': 'multipart/form-data' } }),
        {
            onSuccess: () => {
                notifications.update({
                    id: 'update-ticket',
                    color: 'teal',
                    title: 'Ticket updated',
                    message: 'The ticket is successfully updated.',
                    icon: <IconCheck size={16} />,
                    autoClose: 4000,
                });
                queryClient.invalidateQueries("tickets")
            },
            onError: () => {
                notifications.update({
                    id: 'update-ticket',
                    color: 'red',
                    title: 'Error',
                    message: 'An error has occurred.',
                    icon: <IconExclamationCircle size={16} />,
                    autoClose: 4000,
                });
            }
        }
    );

    const onCreateTicket = (data) => {
        setOpenCreateModal(false);
        notifications.show({
            id: 'create-ticket',
            loading: true,
            title: 'Creating Ticket',
            message: 'The ticket is being created.',
            autoClose: false,
        })
        const formData = new FormData();
        
        formData.append("uid", auth?.uid);
        formData.append("title", data.title);
        formData.append("message", data.message);
        formData.append("category_id", data.category_id);
        formData.append("severity", data.severity);
        formData.append("image", data.file);

        createTicketMutation.mutate(formData)
    }

    const onUpdateStatus = (data) => {
        setOpenUpdateModal(false)
        notifications.show({
            id: 'update-ticket',
            loading: true,
            title: 'Updating Ticket',
            message: 'The ticket is being updated.',
            autoClose: false,
        })
        updateTicketMutation.mutate({...data, uid: auth?.uid})
    }


    return (
        <>
            {/* Create Ticket Modal */}
            <CreateTicketModal opened={openCreateModal} onClose={() => setOpenCreateModal(false)} onSubmit={onCreateTicket}
                    isLoading={createTicketMutation.isLoading} isSuccess={createTicketMutation.isSuccess} isError={createTicketMutation.isError} category={categoryQuery.data}/>
            {/* View Ticket Modal */}
            <ViewTicketModal opened={openViewModal} onClose={() => setOpenViewModal(false)} data={selectedRow}/>
            {/* Update Ticket Status Modal */}
            <UpdateTicketStatusModal opened={openUpdateModal} onClose={() => setOpenUpdateModal(false)} data={selectedRow} onSubmit={onUpdateStatus}
                    isLoading={updateTicketMutation.isLoading} isSuccess={updateTicketMutation.isSuccess} isError={updateTicketMutation.isError} />
            {/* Info Modal */}
            <InfoModal opened={openInfoModal} onClose={()=>setOpenInfoModal(false)}/>

            <Group position='apart'>
                <Text size={'xl'} weight={600}>Tickets</Text>
                <Group spacing={'xs'}>
                    <Button onClick={() => setOpenInfoModal(true)} leftIcon={<IconInfoCircle size={18} />} variant='light' color="gray" size='xs'>
                        Info
                    </Button>
                    {auth?.role === ROLE_CLIENT ?
                    <>
                        <Button onClick={() => setOpenCreateModal(true)} leftIcon={<IconTicket size={18} />} variant='light' color="green" size='xs'>
                            Create Ticket
                        </Button>
                    </>
                    : null}
                </Group>
            </Group>
            <Divider my="md" />
            <DataGrid
                data={data}
                loading={isFetching}
                striped
                highlightOnHover
                withGlobalFilter
                withPagination
                //withColumnFilters
                withSorting
                //withColumnResizing
                columns={[
                    {
                        header: 'No.',
                        cell: (cell) => {
                            return <Text>{cell.row.index + 1}</Text>;
                        },
                        size: 40,
                    },
                    {
                        accessorKey: 'category',
                        header: 'Category',
                        size: 150,
                    },
                    {
                        accessorKey: 'title',
                        header: 'Title',
                        size: 150,
                    },
                    {
                        accessorKey: 'createdAt',
                        header: 'Created At',
                        size: 125,
                        cell: (cell) => {
                            return <Text>{formatDD_MM_YYYY_hh_mm_A(cell.row.original.createdAt)}</Text>
                        }
                    },
                    {
                        // accessorKey: 'createdAt',
                        header: 'User',
                        size: 200,
                        cell: (cell) => {
                            let fullname = cell.row.original.fullname
                            let company = cell.row.original.company
                            return <Text>{`${fullname} (${company})`}</Text>
                        }
                    },
                    {
                        accessorKey: 'status',
                        header: 'Status',
                        size: 140,
                        cell: (cell) => {
                            let status = cell.row.original.status
                            return <Badge color={status === 'completed' ? 'green' : ''}>{status}</Badge>
                        }
                    },
                    {
                        header: 'Action',
                        cell: (cell) => {
                            return <Group position="center">
                                {cell.row.original.deleted ?
                                    <>
                                    {
                                        auth?.role === ROLE_CLIENT ?
                                        <>
                                        <Button component={Link} to={`/ticket/${cell.row.original.id}`} variant={'light'} leftIcon={<IconEye size={18} />} size="xs" color="teal">View Ticket</Button>
                                        </>
                                        : 
                                        <>
                                        <Button component={Link} to={`/ticket/${cell.row.original.id}`} variant={'light'} leftIcon={<IconEye size={18} />} size="xs" color="teal">View Ticket</Button>
                                        <Button onClick={() => { setSelectedRow(cell.row.original); setOpenUpdateModal(true) }} variant={'light'} leftIcon={<IconCheckbox size={18} />} size="xs" color="yellow" disabled >Update Status</Button>
                                        </>
                                    }
                                        
                                        {/* <Button onClick={() => { setSelectedRow(cell.row.original); setOpenDeleteModal(true)}} variant={'light'} leftIcon={<IconArrowBack size={18} />} size="xs" color="lime">Restore</Button> */}
                                    </>
                                    :
                                    <>
                                    {
                                        auth?.role === ROLE_CLIENT ?
                                        <>
                                        <Button component={Link} to={`/ticket/${cell.row.original.id}`} variant={'light'} leftIcon={<IconEye size={18} />} size="xs" color="teal">View Ticket</Button>
                                        </>
                                        : 
                                        <>
                                        <Button component={Link} to={`/ticket/${cell.row.original.id}`} variant={'light'} leftIcon={<IconEye size={18} />} size="xs" color="teal">View Ticket</Button>
                                        <Button onClick={() => { setSelectedRow(cell.row.original); setOpenUpdateModal(true) }} variant={'light'} leftIcon={<IconCheckbox size={18} />} size="xs" color="yellow" >Update Status</Button>
                                        </>
                                    }  
                                        {/* <Button onClick={() => { setSelectedRow(cell.row.original); setOpenDeleteModal(true);}} variant={'light'} leftIcon={<IconTrash size={18} />} size="xs" color="red">Delete</Button> */}
                                    </>
                                }
                            </Group>;
                        },
                        enableColumnFilter: false,
                        enableSorting: false,
                        enableGlobalFilter: false,
                        size: 180,
                    },
                ]}
            />
        </>
    )
}
