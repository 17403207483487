import { Group, Code, Header, MediaQuery, Burger, Image, useMantineTheme, ActionIcon, Tooltip, Text, Container } from '@mantine/core';
import { IconLogout } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';
import useLogout from '../../hooks/useLogout';

import SappotLogo from '../../assets/logo/SappotLogo.png'

import { ROLE_ADMIN } from '../../utils/Constants';

export default function TopHeader({ setOpened, opened }) {
const { auth } = useAuth();
  const theme = useMantineTheme();
    const navigate = useNavigate();
  const logout = useLogout();

  const signOut = async () => {
    await logout();
    navigate('/login');
}

  return (
    <Header height={60} p="md" style={{ backgroundColor: theme.colorScheme === 'dark' && theme.colors.dark[8] }}>
        <Container size={auth?.role === ROLE_ADMIN ? 10000 : 'lg'} p={0} style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
            <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
            />
            </MediaQuery>

            <Group style={{ display: 'flex', width: '100%' }} position="apart">
                <Group position='center'>
                    <Image width={25} src={SappotLogo} alt="Sappot Tech"/>
                    <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
                        <Text fw={'bold'} size={22}>Sappot Tickets</Text>
                    </MediaQuery>
                </Group>
                <Group>
                    <Code style={{ fontWeight: 700 }}>{auth?.fullname}</Code>
                    <Tooltip label="Logout" color="sappotBlue">
                        <ActionIcon
                            onClick={() => signOut()}
                            size="lg"
                            sx={(theme) => ({
                            backgroundColor:
                                theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                                color: theme.colorScheme === 'dark' ? theme.colors.red[6] : theme.colors.red[6],
                            })}
                        >
                            <IconLogout size={18} />
                        </ActionIcon>
                    </Tooltip>
                </Group>
            </Group>
        </Container>
    </Header>
  );
}