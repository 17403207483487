import moment from 'moment';
import momenttz from 'moment-timezone';

export const getCurrentDateTime = () => {
    let date = moment().tz("Asia/Kuala_Lumpur")
    return moment(date).toDate()
}

//Format eg. 31/12/2023 04:48 PM
export const formatDD_MM_YYYY_hh_mm_A = (date) => {
    if(!date) return '-- --- ----';
    let datetz = moment(date).tz("Asia/Kuala_Lumpur")
    return moment(datetz, "YYYY-MM-DD HH:mm:ss").format('DD/MM/YYYY hh:mm A')
}

//Format eg. 28/01/2023
export const formatDD_MM_YYYY = (date) => {
    if(!date) return '--/--/----';
    let datetz = moment(date).tz("Asia/Kuala_Lumpur")
    return moment(datetz, "YYYY-MM-DD HH:mm:ss").format('DD/MM/YYYY')
}
