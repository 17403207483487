import {
    Button, Flex, TextInput, Modal, Stack, Textarea, Image, Text, ScrollArea 
} from '@mantine/core';

import { formatDD_MM_YYYY_hh_mm_A } from '../../utils/DateTime';

export default function ViewTicketModal({ opened, onClose, data }) {

    return (
        <Modal
            centered
            opened={opened}
            onClose={onClose}
            title={'View Ticket'}
            style={{ zIndex: 999 }}
            padding={'xl'}
            scrollAreaComponent={ScrollArea.Autosize}
        >
            <Stack>
                <TextInput
                    label="User"
                    value={`${data?.fullname} (${data?.company})`}
                    readOnly
                />
                <TextInput
                    label="Category"
                    value={data?.category}
                    readOnly
                />
                <TextInput
                    label="Title"
                    value={data?.title}
                    readOnly
                />
                <Textarea
                    label="Message"
                    minRows={4}
                    value={data?.message}
                    readOnly
                />
                <TextInput
                    label="Severity"
                    value={data?.severity}
                    readOnly
                />
                {data?.img ? 
                    <div>
                        <Text fw={500}>Image</Text>
                        <Image width={'100%'} src={data?.img} alt="Image" withPlaceholder />
                    </div>
                : null
                }
                <TextInput
                    label="Created At"
                    value={formatDD_MM_YYYY_hh_mm_A(data?.createdAt)}
                    readOnly
                />
                <TextInput
                    label="Status"
                    value={data?.status}
                    readOnly
                    styles={{input: {textTransform: 'uppercase'}}}
                />
                <Textarea
                    label="Latest Remarks"
                    minRows={4}
                    value={data?.remarks}
                    readOnly
                />
                <TextInput
                    label="Last Updated At"
                    value={formatDD_MM_YYYY_hh_mm_A(data?.updatedAt)}
                    readOnly
                />
            </Stack>
            <Flex justify="flex-start"
                align="center"
                direction="row"
                wrap="wrap"
                gap="md" mt={'xl'}>
                <Button color='gray' sx={{ flex: 1 }} onClick={() => { onClose(); }} variant='light'>
                    Close
                </Button>
            </Flex>
        </Modal>
    )
}