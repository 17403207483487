import { useEffect } from 'react';
import {
    Button, TextInput, Modal, Stack, Flex, Select
} from '@mantine/core';
import { useForm } from '@mantine/form';


export default function EditCategoryModal({ opened, onClose, data, onSubmit, }) {

    const form = useForm({
        initialValues: { id: '', name: '' },

        validate: {
            name: (value) => (value.length < 1 ? 'Please fill in the empty field' : null),
        },
    });

    useEffect(() => {
        if (data != null) {
            form.setValues({ id: data.id, name: data.name, });
        }
    }, [data, opened]);

    // useEffect(() => {
    //     if (form.values.role === 'client') {
    //         form.setValues({company: data.company})
    //     }
    //     else form.setValues({company: 'Sappot IT Sdn Bhd'});
    // }, [form.values.role]);

    return (
        <Modal
            centered
            opened={opened}
            onClose={onClose}
            title={'Edit Category'}
            style={{ zIndex: 999 }}
            padding={'xl'}
        >
            <form onSubmit={form.onSubmit((values) => onSubmit({ ...values }))} autoComplete="off">
                <Stack>
                    <TextInput
                        label="Name"
                        {...form.getInputProps('name')}
                    />
                </Stack>
                <Flex justify="flex-start"
                    align="center"
                    direction="row"
                    wrap="wrap"
                    gap="md" mt={'xl'}>
                    <Button color='gray' sx={{ flex: 1 }} onClick={() => { onClose() }} variant='light'>
                        Cancel
                    </Button>
                    <Button type="submit" sx={{ flex: 1 }} variant='light'>
                        Update
                    </Button>
                </Flex>
            </form>
        </Modal>
    )
}