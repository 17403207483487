import React from 'react'
import { Group, Text, Divider, Button, Badge, Anchor } from '@mantine/core'
import { useState } from 'react'
import { IconTicket, IconCheckbox, IconCheck, IconExclamationCircle, IconEye } from '@tabler/icons-react'
import { DataGrid } from 'mantine-data-grid';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { notifications } from '@mantine/notifications';
import { useParams, Link } from 'react-router-dom';

import ViewTicketModal from '../../components/Tickets/ViewTicketModal';
import CreateTicketModal from '../../components/Tickets/CreateTicketModal';
import UpdateTicketStatusModal from '../../components/Tickets/UpdateTicketStatusModal';
import ViewChronologyModal from '../../components/Chronology/ViewChronologyModal';

import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

import { ROLE_CLIENT } from '../../utils/Constants';

import { formatDD_MM_YYYY_hh_mm_A } from '../../utils/DateTime';

export default function Chronology() {
    const axiosPrivate = useAxiosPrivate();
    const queryClient = useQueryClient();
    const { auth } = useAuth()
    const { id } = useParams()

    const [openViewModal, setOpenViewModal] = useState(false)
    const [openCreateModal, setOpenCreateModal] = useState(false)
    const [openUpdateModal, setOpenUpdateModal] = useState(false)
    // const [openDeleteModal, setOpenDeleteModal] = useState(false)
    // const [openEditModal, setOpenEditModal] = useState(false)

    const [selectedRow, setSelectedRow] = useState(null)

    const { isLoading, isFetching, isError, error, data, } = useQuery(
        "chronology",
        async ({ signal }) => (await axiosPrivate.post('/chronology/read-by-ticket-id', {ticketId: id}, { signal })).data.data,
        {
            initialData: [],
            onError: () => {
                notifications.show({
                    id: 'error',
                    title: `Error`,
                    message: `Unable to load data`,
                })
            }
        },
    );

    const createTicketMutation = useMutation(
        (data) => axiosPrivate.post(`/tickets/create`, data, { headers: { 'Content-Type': 'multipart/form-data' } }),
        {
            onSuccess: () => {
                notifications.update({
                    id: 'create-ticket',
                    color: 'teal',
                    title: 'Ticket created',
                    message: 'The ticket is successfully created.',
                    icon: <IconCheck size={16} />,
                    autoClose: 4000,
                });
                queryClient.invalidateQueries("tickets")
            },
            onError: () => {
                notifications.update({
                    id: 'create-ticket',
                    color: 'red',
                    title: 'Error',
                    message: 'An error has occurred.',
                    icon: <IconExclamationCircle size={16} />,
                    autoClose: 4000,
                });
            }
        }
    );

    
    const updateTicketMutation = useMutation(
        (data) => axiosPrivate.post(`/tickets/update-status`, data, { headers: { 'Content-Type': 'multipart/form-data' } }),
        {
            onSuccess: () => {
                notifications.update({
                    id: 'update-ticket',
                    color: 'teal',
                    title: 'Ticket updated',
                    message: 'The ticket is successfully updated.',
                    icon: <IconCheck size={16} />,
                    autoClose: 4000,
                });
                queryClient.invalidateQueries("tickets")
            },
            onError: () => {
                notifications.update({
                    id: 'update-ticket',
                    color: 'red',
                    title: 'Error',
                    message: 'An error has occurred.',
                    icon: <IconExclamationCircle size={16} />,
                    autoClose: 4000,
                });
            }
        }
    );

    const onCreateTicket = (data) => {
        setOpenCreateModal(false);
        notifications.show({
            id: 'create-ticket',
            loading: true,
            title: 'Creating Ticket',
            message: 'The ticket is being created.',
            autoClose: false,
        })
        const formData = new FormData();
        
        formData.append("uid", auth?.uid);
        formData.append("title", data.title);
        formData.append("message", data.message);
        formData.append("image", data.file);

        createTicketMutation.mutate(formData)
    }

    const onUpdateStatus = (data) => {
        setOpenUpdateModal(false)
        notifications.show({
            id: 'update-ticket',
            loading: true,
            title: 'Updating Ticket',
            message: 'The ticket is being updated.',
            autoClose: false,
        })
        updateTicketMutation.mutate(data)
    }


    return (
        <>
            {/* Create Modal */}
            <CreateTicketModal opened={openCreateModal} onClose={() => setOpenCreateModal(false)} onSubmit={onCreateTicket}
                    isLoading={createTicketMutation.isLoading} isSuccess={createTicketMutation.isSuccess} isError={createTicketMutation.isError} />
            {/* View Modal */}
            <ViewChronologyModal opened={openViewModal} onClose={() => setOpenViewModal(false)} data={selectedRow}/>
            {/* Update Ticket Status Modal */}
            <UpdateTicketStatusModal opened={openUpdateModal} onClose={() => setOpenUpdateModal(false)} data={selectedRow} onSubmit={onUpdateStatus}
                    isLoading={updateTicketMutation.isLoading} isSuccess={updateTicketMutation.isSuccess} isError={updateTicketMutation.isError} />

            <Group position='apart'>
                <Group spacing="xs">
                    <Anchor fz={'xl'} fw={600} component={Link} to={'/'}>Tickets</Anchor>
                    <Anchor fz={'xl'} fw={600} component={Link} to={`/ticket/${id}`}>/ View Ticket</Anchor>
                    <Text size={'xl'} weight={600}>/ Chronology</Text>
                </Group>
            </Group>
            <Divider my="md" />
            <DataGrid
                data={data}
                loading={isFetching}
                striped
                highlightOnHover
                withGlobalFilter
                withPagination
                //withColumnFilters
                withSorting
                //withColumnResizing
                columns={[
                    {
                        header: 'No.',
                        cell: (cell) => {
                            return <Text>{cell.row.index + 1}</Text>;
                        },
                        size: 60,
                    },
                    {
                        header: 'User',
                        size: 150,
                        cell: (cell) => {
                            let fullname = cell.row.original.fullname
                            // let company = cell.row.original.company
                            return <Text>{`${fullname}`}</Text>
                        }
                    },
                    {
                        accessorKey: 'remarks',
                        header: 'Remarks',
                        size: 200,
                    },
                    {
                        accessorKey: 'status',
                        header: 'Type',
                        size: 100,
                        cell: (cell) => {
                            let status = cell.row.original.status
                            return <Badge color={status === 'completed' ? 'green' : ''}>{status}</Badge>
                        }
                    },
                    {
                        accessorKey: 'createdAt',
                        header: 'Created At',
                        size: 125,
                        cell: (cell) => {
                            return <Text>{formatDD_MM_YYYY_hh_mm_A(cell.row.original.createdAt)}</Text>
                        }
                    },
                    {
                        header: 'Action',
                        cell: (cell) => {
                            return <Group position="center">
                                <Button onClick={() => { setSelectedRow(cell.row.original); setOpenViewModal(true) }} variant={'light'} leftIcon={<IconEye size={18} />} size="xs">View</Button>
                            </Group>;
                        },
                        enableColumnFilter: false,
                        enableSorting: false,
                        enableGlobalFilter: false,
                        size: 100,
                    },
                ]}
            />
        </>
    )
}
