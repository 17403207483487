import { MantineProvider, ColorSchemeProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { useLocalStorage } from '@mantine/hooks';
import { Routes, Route, Navigate } from 'react-router-dom'

import RequireAuth from './components/General/RequireAuth';
import PersistLogin from './components/General/PersistLogin';

import SappotAppShell from './components/General/SappotAppShell';

import Login from './pages/Auth/Login';
import NotFound from './pages/Misc/NotFound';

import Tickets from './pages/Tickets/Tickets';

import ViewTicket from './pages/Tickets/ViewTicket';

import Chronology from './pages/Chronology/Chronology';
import Client from './pages/Client/Client';
import Developer from './pages/Developer/Developer';
import Category from './pages/Category/Category';
import Admin from './pages/Admin/Admin';



import { ROLE_ADMIN, ROLE_DEVELOPER, ROLE_CLIENT } from './utils/Constants';

function App() {

    const [colorScheme, setColorScheme] = useLocalStorage({
        key: 'sappot-color-scheme',
        defaultValue: 'dark',
        getInitialValueInEffect: true,
    });


    const toggleColorScheme = (value) =>
        setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));


    return (
        <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
            <MantineProvider 
            theme={{
                colorScheme: colorScheme,
                colors: {
                brand: ["#defdff","#cbf3ff","#9ce5fc","#69d6f9","#3fc9f6","#23c1f4","#00bdf4","#00a6da","#0095c4","#0081ae"],
                sappotBlue: ["#defdff","#cbf3ff","#9ce5fc","#69d6f9","#3fc9f6","#23c1f4","#00bdf4","#00a6da","#0095c4","#0081ae"],
                },
                primaryColor: 'sappotBlue',
                activeStyles: { transform: 'scale(0.98)' }
            }}
            withGlobalStyles withNormalizeCSS
            >
                <Notifications position="top-center" zIndex={2077} />
                <Routes>
                    {/* Protected Route */}
                    <Route element={<PersistLogin />}>
                        <Route element={<RequireAuth allowedRoles={[ROLE_ADMIN, ROLE_CLIENT, ROLE_DEVELOPER]} />}>
                            <Route element={<SappotAppShell />}>
                                <Route path='/' element={<Tickets />} />
                                <Route path='/chronology/:id' element={<Chronology />} />

                                <Route path='/ticket/:id' element={<ViewTicket />}/>
                                <Route path='/ticket/chronology/:id' element={<Chronology />}/>
                            </Route>
                        </Route>
                        
                        <Route element={<RequireAuth allowedRoles={[ROLE_ADMIN]} />}>
                            <Route element={<SappotAppShell />}>
                                <Route path='/client' element={<Client />}/>
                                <Route path='/client/category/:uid' element={<Category />}/>
                                <Route path='/developer' element={<Developer />}/>
                                <Route path='/admin' element={<Admin />}/>
                            </Route>
                        </Route>
                    </Route>

                    {/* Public Route */}
                    <Route path='/login' element={<Login />} />

                    <Route path='/404' element={<NotFound />} />

                    {/* If route not found redirect to home page for now */}
                    <Route path="*" element={<Navigate to="/404" replace />} />
                </Routes>
            </MantineProvider>
        </ColorSchemeProvider>
    );
}

export default App;
