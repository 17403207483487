import {
    Button, Flex, TextInput, Modal, Stack, Textarea, Image, Text, ScrollArea 
} from '@mantine/core';

import { formatDD_MM_YYYY_hh_mm_A } from '../../utils/DateTime';

export default function ViewChronologyModal({ opened, onClose, data }) {

    return (
        <Modal
            centered
            opened={opened}
            onClose={onClose}
            title={'View Details'}
            style={{ zIndex: 999 }}
            padding={'xl'}
            scrollAreaComponent={ScrollArea.Autosize}
        >
            <Stack>
                <TextInput
                    label="User"
                    value={`${data?.fullname}`}
                    readOnly
                />
                <TextInput
                    label="Type"
                    value={data?.status}
                    readOnly
                />
                <Textarea
                    label="Remarks"
                    minRows={4}
                    value={data?.remarks}
                    readOnly
                />
                <TextInput
                    label="Created At"
                    value={formatDD_MM_YYYY_hh_mm_A(data?.createdAt)}
                    readOnly
                />
            </Stack>
            <Flex justify="flex-start"
                align="center"
                direction="row"
                wrap="wrap"
                gap="md" mt={'xl'}>
                <Button color='gray' sx={{ flex: 1 }} onClick={() => { onClose(); }} variant='light'>
                    Close
                </Button>
            </Flex>
        </Modal>
    )
}