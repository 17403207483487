import { useEffect } from 'react';
import {
    Button, TextInput, Modal, Stack, Flex, Select
} from '@mantine/core';
import { useForm } from '@mantine/form';


export default function EditUserModal({ opened, onClose, data, onSubmit, role }) {

    const form = useForm({
        initialValues: { uid: '', fullname: '', email: '', company: '', role: role },

        validate: {
            fullname: (value) => (value.length < 1 ? 'Please fill in the empty field' : null),
            email: (value) => (value.length < 1 ? 'Please fill in the empty field' : /^\S+@\S+$/.test(value) ? null : 'Invalid Email Address'),
            company: (value) => (value.length < 1 ? 'Please fill in the empty field' : null),
            role: (value) => (value.length < 1 ? 'Please fill in the empty field' : null),
        },
    });

    useEffect(() => {
        if (data != null) {
            form.setValues({ uid: data.id, fullname: data.fullname, email: data.email, role: data.role, company: data.company });
        }
    }, [data, opened]);

    // useEffect(() => {
    //     if (form.values.role === 'client') {
    //         form.setValues({company: data.company})
    //     }
    //     else form.setValues({company: 'Sappot IT Sdn Bhd'});
    // }, [form.values.role]);

    return (
        <Modal
            centered
            opened={opened}
            onClose={onClose}
            title={'Edit User'}
            style={{ zIndex: 999 }}
            padding={'xl'}
        >
            <form onSubmit={form.onSubmit((values) => onSubmit({ ...values }))} autoComplete="off">
                <Stack>
                    <TextInput
                        label="Full Name"
                        {...form.getInputProps('fullname')}
                    />
                    <TextInput
                        label="Email"
                        {...form.getInputProps('email')}
                    />
                    {/* <Select
                        label="Role"
                        data={[
                            { value: 'client', label: 'Client' },
                            { value: 'developer', label: 'Developer' },
                            { value: 'admin', label: 'Admin' },
                        ]}
                        {...form.getInputProps('role')}
                    /> */}
                    {form.values.role === 'client' ? 
                        <TextInput
                            label="Company"
                            {...form.getInputProps('company')}
                        />
                    : null
                    }
                </Stack>
                <Flex justify="flex-start"
                    align="center"
                    direction="row"
                    wrap="wrap"
                    gap="md" mt={'xl'}>
                    <Button color='gray' sx={{ flex: 1 }} onClick={() => { onClose() }} variant='light'>
                        Cancel
                    </Button>
                    <Button type="submit" sx={{ flex: 1 }} variant='light'>
                        Update
                    </Button>
                </Flex>
            </form>
        </Modal>
    )
}