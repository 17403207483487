import React from 'react'
import { Group, Text, Divider, Button, Badge } from '@mantine/core'
import { useState } from 'react'
import { IconUser, IconPencil, IconArrowBack, IconTrash, IconCheck, IconExclamationCircle } from '@tabler/icons-react'
import { DataGrid } from 'mantine-data-grid';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { notifications } from '@mantine/notifications';

import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

import CreateUserModal from '../../components/Users/CreateUserModal';
import EditUserModal from '../../components/Users/EditUserModal';
import ConfirmationModal from '../../components/General/ConfirmationModal';

import { ROLE_ADMIN, ROLE_DEVELOPER } from '../../utils/Constants';

export default function Developer() {
    const axiosPrivate = useAxiosPrivate();
    const queryClient = useQueryClient();
    const { auth } = useAuth()

    const [openCreateModal, setOpenCreateModal] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openRestoreModal, setOpenRestoreModal] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)

    const [selectedRow, setSelectedRow] = useState(null)

    const { isFetching, data, } = useQuery(
        "users-developer",
        async ({ signal }) => (await axiosPrivate.post(`/accounts/read-by-role`, {role: ROLE_DEVELOPER}, { signal })).data.users,
        {
            initialData: [],
            onError: () => {
                notifications.show({
                    id: 'error',
                    title: `Error`,
                    message: `Unable to load data`,
                })
            }
        },
    );

    const createUserMutation = useMutation(
        (data) => axiosPrivate.post(`/accounts/add-user`, data),
        {
            onSuccess: () => {
                notifications.update({
                    id: 'create-user-developer',
                    color: 'teal',
                    title: 'User created',
                    message: 'The user account is successfully created.',
                    icon: <IconCheck size={16} />,
                    autoClose: 4000,
                });
                queryClient.invalidateQueries("users-developer")
            },
            onError: () => {
                notifications.update({
                    id: 'create-user-developer',
                    color: 'red',
                    title: 'Error',
                    message: 'An error has occurred.',
                    icon: <IconExclamationCircle size={16} />,
                    autoClose: 4000,
                });
            }
        }
    );

    const editUserMutation = useMutation(

        (data) => axiosPrivate.post(`/accounts/edit-profile`, data),
        {
            onSuccess: () => {
                notifications.update({
                    id: 'edit-user-developer',
                    color: 'teal',
                    title: 'User updated',
                    message: 'The user is successfully updated.',
                    icon: <IconCheck size={16} />,
                    autoClose: 4000,
                });
                queryClient.invalidateQueries("users-developer")

            },
            onError: (err) => {
                let errMsg = err?.response?.data?.message
                notifications.update({
                    id: 'edit-user-developer',
                    color: 'red',
                    title: 'Error',
                    message: errMsg ? errMsg : 'An error has occurred.',
                    icon: <IconExclamationCircle size={16} />,
                    autoClose: 4000,
                });
            }
        }
    );

    const deleteUserMutation = useMutation(
        (uid) => axiosPrivate.post('/accounts/delete', {uid: uid}),
        {
            onSuccess: () => {
                notifications.update({
                    id: 'delete-user-developer',
                    color: 'teal',
                    title: `User deleted`,
                    message: `The user is successfully deleted.`,
                    icon: <IconCheck size={16} />,
                    autoClose: 4000,
                });
                queryClient.invalidateQueries("users-developer")
            },
            onError: () => {
                notifications.update({
                    id: 'delete-user-developer',
                    color: 'red',
                    title: 'Error',
                    message: 'An error has occurred.',
                    icon: <IconExclamationCircle size={16} />,
                    autoClose: 4000,
                });
            }
        }
    );

    const restoreUserMutation = useMutation(
        (uid) => axiosPrivate.post('/accounts/restore', {uid: uid}),
        {
            onSuccess: () => {
                notifications.update({
                    id: 'restore-user-developer',
                    color: 'teal',
                    title: `User restored`,
                    message: `The user is successfully restored.`,
                    icon: <IconCheck size={16} />,
                    autoClose: 4000,
                });
                queryClient.invalidateQueries("users-developer")
            },
            onError: () => {
                notifications.update({
                    id: 'restore-user-developer',
                    color: 'red',
                    title: 'Error',
                    message: 'An error has occurred.',
                    icon: <IconExclamationCircle size={16} />,
                    autoClose: 4000,
                });
            }
        }
    );

    const onCreateUser = (data) => {
        setOpenCreateModal(false);
        notifications.show({
            id: 'create-user-developer',
            loading: true,
            title: 'Creating User',
            message: 'The user account is being created.',
            autoClose: false,
        })
        createUserMutation.mutate({ ...data })
    }

    const onEditUser = (data) => {
        setOpenEditModal(false);
        notifications.show({
            id: 'edit-user-developer',
            loading: true,
            title: 'Updating User',
            message: 'The user account is being updated.',
            autoClose: false,
        })
        editUserMutation.mutate({ ...data })
    }

    const onDeleteUser = () => {
        setOpenDeleteModal(false)
        notifications.show({
            id: 'delete-user-developer',
            loading: true,
            title: `Deleting User`,
            message: `The user account is being deleted.`,
            autoClose: false,
        })
        deleteUserMutation.mutate(selectedRow?.id)
    }

    const onRestoreUser = () => {
        setOpenRestoreModal(false)
        notifications.show({
            id: 'restore-user-developer',
            loading: true,
            title: `Restoring User`,
            message: `The user account is being restored.`,
            autoClose: false,
        })
        restoreUserMutation.mutate(selectedRow?.id)
    }

    return (
        <>
            {/* Create Modal */}
            <CreateUserModal opened={openCreateModal} onClose={() => setOpenCreateModal(false)} onSubmit={onCreateUser}
                isLoading={createUserMutation.isLoading} isSuccess={createUserMutation.isSuccess} isError={createUserMutation.isError} role={ROLE_DEVELOPER}/>
            {/* Edit Modal */}
            <EditUserModal data={selectedRow} opened={openEditModal} onClose={() => setOpenEditModal(false)} onSubmit={onEditUser}
                isLoading={editUserMutation.isLoading} isSuccess={editUserMutation.isSuccess} isError={editUserMutation.isError} role={ROLE_DEVELOPER}/>
            {/* Delete Confirmation Modal */}
            <ConfirmationModal opened={openDeleteModal} onClose={() => setOpenDeleteModal(false)} text={`Are you sure you want to delete ${selectedRow?.fullname}?`} onClick={onDeleteUser} />
            {/* Restore Confirmation Modal */}
            <ConfirmationModal opened={openRestoreModal} onClose={() => setOpenRestoreModal(false)} text={`Are you sure you want to restore ${selectedRow?.fullname}?`} onClick={onRestoreUser} />
            <Group position='apart'>
                <Text size={'xl'} weight={600}>Developer</Text>
                <Button onClick={() => setOpenCreateModal(true)} leftIcon={<IconUser size={18} />} variant='light' color="green" size='xs'>
                    Create Developer
                </Button>
            </Group>
            <Divider my="md" />
            <DataGrid
                data={data}
                loading={isFetching}
                striped
                highlightOnHover
                withGlobalFilter
                withPagination
                //withColumnFilters
                withSorting
                //withColumnResizing
                columns={[
                    {
                        header: 'No.',
                        cell: (cell) => {
                            return <Text>{cell.row.index + 1}</Text>;
                        },
                        size: 60,
                    },
                    {
                        accessorKey: 'fullname',
                        header: 'Name',
                        size: 150,
                    },
                    {
                        accessorKey: 'email',
                        header: 'Email',
                        size: 200,
                    },
                    {
                        accessorKey: 'role',
                        header: 'Role',
                        size: 200,
                        cell: (cell) => {
                            let role = cell.row.original.role
                            return <Badge color={role === ROLE_ADMIN ? 'red' : role === ROLE_DEVELOPER ? 'grape' : 'cyan'}>{role}</Badge>
                        }
                    },
                    {
                        accessorKey: 'company',
                        header: 'Company',
                        size: 200,
                    },
                    {
                        header: 'Action',
                        cell: (cell) => {
                            return <Group position="center">
                                {cell.row.original.deleted ?
                                    <>
                                        <Button onClick={() => { setSelectedRow(cell.row.original); setOpenEditModal(true) }} variant={'light'} leftIcon={<IconPencil size={18} />} size="xs" color="yellow" disabled >Edit</Button>
                                        <Button onClick={() => { setSelectedRow(cell.row.original); setOpenRestoreModal(true)}} variant={'light'} leftIcon={<IconArrowBack size={18} />} size="xs" color="lime">Restore</Button>
                                    </>
                                    :
                                    <>
                                        <Button onClick={() => { setSelectedRow(cell.row.original); setOpenEditModal(true) }} variant={'light'} leftIcon={<IconPencil size={18} />} size="xs" color="yellow" >Edit</Button>
                                        <Button disabled={auth?.uid === cell.row.original.id} onClick={() => { setSelectedRow(cell.row.original); setOpenDeleteModal(true);}} variant={'light'} leftIcon={<IconTrash size={18} />} size="xs" color="red">Delete</Button>
                                    </>
                                }
                            </Group>;
                        },
                        enableColumnFilter: false,
                        enableSorting: false,
                        enableGlobalFilter: false,
                        size: 265,
                    },
                ]}
            />
        </>
    )
}
