import {
    Button, Flex, Modal, Stack, ScrollArea, Table,
} from '@mantine/core';

/*
Open: The ticket has been created and logged in the system but has not yet been assigned to anyone for resolution.
Assigned: The ticket has been assigned to a specific person or team who will be responsible for addressing it.
In Progress: Work on the ticket has started, and the assigned person or team is actively working to resolve the issue or complete the task.
Pending: The ticket is awaiting some action or information before it can be further worked on. This could be waiting for a response from the requester, more information, or a necessary resource.
On Hold: The ticket cannot be worked on for some reason and is temporarily paused. This could be due to external dependencies, waiting for customer feedback, or other blocking issues.
Resolved: The ticket has been addressed, and the solution has been implemented. The issue is considered fixed or the task completed, but it hasn't been confirmed by the requester yet.
Resolved - Client: The ticket has been resolved and confirmed by the client or requester. This status is often used when client confirmation is necessary to ensure the issue is truly fixed from the client's perspective.
Closed: The ticket has been fully resolved, confirmed, and no further action is required. This is the final state for a ticket, indicating it has been completely dealt with.
Cancelled: The ticket has been canceled and will not be worked on. This can happen if the issue is no longer relevant, was created in error, or the request was withdrawn
*/

const STATUS_DETAILS = [
    {
        name: 'Open',
        details: 'The ticket has been created and logged in the system but has not yet been assigned to anyone for resolution.',
    },
    {
        name: 'Assigned',
        details: 'The ticket has been assigned to a specific person or team who will be responsible for addressing it.',
    },
    {
        name: 'In Progress',
        details: 'Work on the ticket has started, and the assigned person or team is actively working to resolve the issue or complete the task.',
    },
    {
        name: 'Pending',
        details: 'The ticket is awaiting some action or information before it can be further worked on. This could be waiting for a response from the requester, more information, or a necessary resource.',
    },
    {
        name: 'On Hold',
        details: 'The ticket cannot be worked on for some reason and is temporarily paused. This could be due to external dependencies, waiting for customer feedback, or other blocking issues.',
    },
    {
        name: 'Resolved',
        details: `The ticket has been addressed, and the solution has been implemented. The issue is considered fixed or the task completed, but it hasn't been confirmed by the requester yet.`,
    },
    {
        name: 'Resolved - Client',
        details: `The ticket has been resolved and confirmed by the client or requester. This status is often used when client confirmation is necessary to ensure the issue is truly fixed from the client's perspective.`,
    },
    {
        name: 'Closed',
        details: `The ticket has been fully resolved, confirmed, and no further action is required. This is the final state for a ticket, indicating it has been completely dealt with.`,
    },
    {
        name: 'Cancelled',
        details: `The ticket has been canceled and will not be worked on. This can happen if the issue is no longer relevant, was created in error, or the request was withdrawn`,
    },
]

export default function InfoModal({ opened, onClose }) {

    const rows = STATUS_DETAILS.map((item) => (
        <tr key={item.name}>
          <td>{item.name}</td>
          <td>{item.details}</td>
        </tr>
      ));

    return (
        <Modal
            centered
            opened={opened}
            onClose={onClose}
            title={'Information'}
            style={{ zIndex: 999 }}
            padding={'xl'}
            scrollAreaComponent={ScrollArea.Autosize}
            size={'lg'}
        >
            <Stack>
            <Table withBorder withColumnBorders>
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>Status Details</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </Table>
            </Stack>
            <Flex justify="flex-start"
                align="center"
                direction="row"
                wrap="wrap"
                gap="md" mt={'xl'}>
                <Button color='gray' sx={{ flex: 1 }} onClick={() => { onClose(); }} variant='light'>
                    Close
                </Button>
            </Flex>
        </Modal>
    )
}