import React, { useRef } from 'react'
import { Group, Text, Divider, Button, Badge, Anchor, Grid, Timeline, Stack, TextInput, Textarea, Image, ScrollArea, Flex, Box, createStyles, Skeleton, } from '@mantine/core'
import { useState } from 'react'
import { IconTicket, IconCheckbox, IconCheck, IconExclamationCircle, IconHistory, } from '@tabler/icons-react'
import { DataGrid } from 'mantine-data-grid';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { notifications } from '@mantine/notifications';
import { useParams, Link } from 'react-router-dom';
import { useForm } from '@mantine/form';

import UpdateTicketStatusModal from '../../components/Tickets/UpdateTicketStatusModal';
import ViewChronologyModal from '../../components/Chronology/ViewChronologyModal';
import ConfirmationModal from '../../components/General/ConfirmationModal';

import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

import { ROLE_CLIENT } from '../../utils/Constants';

import { formatDD_MM_YYYY_hh_mm_A } from '../../utils/DateTime';

const useStyles = createStyles((theme) => ({  

    timelineWrapper: {
        height: '40vh',
        minHeight: 250,
        backgroundColor: '#25262B',

        [theme.fn.smallerThan('sm')]: {
            // fontSize: 32,
        },
    },

}));

export default function ViewTicket() {
    const { classes } = useStyles();
    const viewport = useRef(null);
    const axiosPrivate = useAxiosPrivate();
    const queryClient = useQueryClient();
    const { auth } = useAuth()
    const { id } = useParams()

    const [openViewModal, setOpenViewModal] = useState(false)
    const [openCreateModal, setOpenCreateModal] = useState(false)
    const [openUpdateModal, setOpenUpdateModal] = useState(false)
    const [openResolvedModal, setOpenResolvedModal] = useState(false)
    // const [openEditModal, setOpenEditModal] = useState(false)

    const [selectedRow, setSelectedRow] = useState(null)

    const form = useForm({
        initialValues: { remarks: '' },

        validate: {
            remarks: (value) => (value.length < 1 ? 'Please fill in the empty field' : null),
        },
    });

    const { isLoading, isFetching, isError, error, data, } = useQuery(
        "ticket",
        async ({ signal }) => (await axiosPrivate.post('/tickets/read-by-id', {ticketId: id}, { signal })).data.data,
        {
            initialData: {
                ticket: null,
                chronology: [],
            },
            onSuccess: () => {
                scrollToBottom()
            },
            onError: () => {
                notifications.show({
                    id: 'error',
                    title: `Error`,
                    message: `Unable to load data`,
                })
            },
            cacheTime: 0,
        },
    );

    const addCommentMutation = useMutation(
        (data) => axiosPrivate.post(`/chronology/add-comment`, data, { headers: { 'Content-Type': 'multipart/form-data' } }),
        {
            onSuccess: () => {
                notifications.update({
                    id: 'add-comment',
                    color: 'teal',
                    title: 'Comment Added',
                    message: 'The comment is successfully added.',
                    icon: <IconCheck size={16} />,
                    autoClose: 4000,
                });
                form.reset()
                queryClient.invalidateQueries("ticket")
            },
            onError: () => {
                notifications.update({
                    id: 'add-comment',
                    color: 'red',
                    title: 'Error',
                    message: 'An error has occurred.',
                    icon: <IconExclamationCircle size={16} />,
                    autoClose: 4000,
                });
            }
        }
    );
    
    const updateTicketMutation = useMutation(
        (data) => axiosPrivate.post(`/tickets/update-status`, data, { headers: { 'Content-Type': 'multipart/form-data' } }),
        {
            onSuccess: () => {
                notifications.update({
                    id: 'update-ticket',
                    color: 'teal',
                    title: 'Ticket updated',
                    message: 'The ticket is successfully updated.',
                    icon: <IconCheck size={16} />,
                    autoClose: 4000,
                });
                queryClient.invalidateQueries("ticket")
            },
            onError: () => {
                notifications.update({
                    id: 'update-ticket',
                    color: 'red',
                    title: 'Error',
                    message: 'An error has occurred.',
                    icon: <IconExclamationCircle size={16} />,
                    autoClose: 4000,
                });
            }
        }
    );

    const onAddComment = (data) => {
        // setOpenCreateModal(false);
        notifications.show({
            id: 'add-comment',
            loading: true,
            title: 'Adding Comment',
            message: 'The ticket is being created.',
            autoClose: false,
        })
        const formData = new FormData();
        
        formData.append("id", id);
        formData.append("remarks", data.remarks);
        formData.append("uid", auth?.uid);

        addCommentMutation.mutate(formData)
    }

    const onUpdateStatus = (data) => {
        setOpenUpdateModal(false)
        notifications.show({
            id: 'update-ticket',
            loading: true,
            title: 'Updating Ticket',
            message: 'The ticket is being updated.',
            autoClose: false,
        })
        updateTicketMutation.mutate({...data, uid: auth?.uid})

    }

    const onTicketResolved = () => {
        setOpenResolvedModal(false)
        notifications.show({
            id: 'update-ticket',
            loading: true,
            title: 'Updating Ticket',
            message: 'The ticket is being updated.',
            autoClose: false,
        })

        const data = {
            id: id,
            status: 'Resolved - Client',
            remarks: 'I am confirmed that this issue has been resolved.',
            uid: auth?.uid,
        }

        updateTicketMutation.mutate({...data})
    }

    const scrollToBottom = () => {
        setTimeout(() => {
            if(viewport.current){
                viewport.current.scrollTo({ top: viewport.current.scrollHeight, behavior: 'smooth' });
            }
        }, 500);
    }

    const timeline = (
        data.chronology.map((item, i)=>(
            <Timeline.Item key={i} bullet={<Text fz={10}>{i+1}</Text>} fz={18} title={item.status}>
                <Text size="sm">{item.fullname}</Text>
                <Text color="dimmed" size="md">{item.remarks}</Text>
                <Text size="xs" mt={4}>{formatDD_MM_YYYY_hh_mm_A(item.createdAt)}</Text>
            </Timeline.Item>
        ))
    )


    return (
        <>
            {/* View Modal */}
            <ViewChronologyModal opened={openViewModal} onClose={() => setOpenViewModal(false)} data={selectedRow}/>
            {/* Update Ticket Status Modal */}
            <UpdateTicketStatusModal opened={openUpdateModal} onClose={() => setOpenUpdateModal(false)} data={data.ticket} onSubmit={onUpdateStatus}
                    isLoading={updateTicketMutation.isLoading} isSuccess={updateTicketMutation.isSuccess} isError={updateTicketMutation.isError} />
            {/* Customer Resolved Confirmation Modal */}
            <ConfirmationModal opened={openResolvedModal} onClose={() => setOpenResolvedModal(false)} title='Client Confirmation' text={`I am confirmed that this issue has been resolved.`} onClick={onTicketResolved} />

            <Group position='apart'>
                <Group spacing="xs">
                    <Anchor fz={'xl'} fw={600} component={Link} to={'/'}>Tickets</Anchor>
                    <Text size={'xl'} weight={600}>/ View Ticket</Text>
                </Group>
                <Group spacing="xs">
                    {auth?.role === ROLE_CLIENT ?
                        <>
                            {data?.ticket?.status === 'Resolved' ?
                                <Button onClick={() => {setOpenResolvedModal(true) }} variant={'light'} leftIcon={<IconCheckbox size={18} />} size="xs" color="green">Resolved Ticket</Button>
                            : null
                            }
                        </>
                        :
                        <>
                            <Button onClick={() => {setOpenUpdateModal(true) }} variant={'light'} leftIcon={<IconCheckbox size={18} />} size="xs" color="yellow">Update Status</Button>
                        </>
                    }
                </Group>
            </Group>
            <Divider my="md" />
            <Skeleton visible={!data?.ticket && (isFetching || isError)} animate={!isError}>
            <Grid grow gutter={'xl'}>
                <Grid.Col md={6} lg={6}>
                    <Text size="lg" fw={500}>Ticket Details</Text>
                    <Divider my="md" />
                    <Stack>
                        <TextInput
                            label="User"
                            value={`${data?.ticket?.fullname} (${data?.ticket?.company})`}
                            readOnly
                        />
                        <TextInput
                            label="Category"
                            value={data?.ticket?.category}
                            readOnly
                        />
                        <TextInput
                            label="Title"
                            value={data?.ticket?.title}
                            readOnly
                        />
                        <Textarea
                            label="Message"
                            minRows={4}
                            value={data?.ticket?.message}
                            readOnly
                        />
                        <TextInput
                            label="Severity"
                            value={data?.ticket?.severity}
                            readOnly
                        />
                        {data?.ticket?.img ? 
                            <div>
                                <Text fw={500}>Image</Text>
                                <Image width={'100%'} src={data?.ticket.img} alt="Image" withPlaceholder />
                            </div>
                        : null
                        }
                        <TextInput
                            label="Created At"
                            value={formatDD_MM_YYYY_hh_mm_A(data?.ticket?.createdAt)}
                            readOnly
                        />
                        <TextInput
                            label="Status"
                            value={data?.ticket?.status}
                            readOnly
                        />
                        {/* <Textarea
                            label="Latest Remarks"
                            minRows={4}
                            value={data?.ticket?.remarks}
                            readOnly
                        /> */}
                        {/* <TextInput
                            label="Last Updated At"
                            value={formatDD_MM_YYYY_hh_mm_A(data?.ticket?.updatedAt)}
                            readOnly
                        /> */}
                    </Stack>
                </Grid.Col>
                <Grid.Col md={6} lg={6}>
                    <Group position='apart'>
                        <Text size="lg" fw={500}>Timeline</Text>
                        <Button component={Link} to={`/ticket/chronology/${id}`} variant={'light'} leftIcon={<IconHistory size={18} />} size="xs" color="gray">View Timeline</Button>
                    </Group>
                    <Divider my="md" />
                    <form onSubmit={form.onSubmit((values) => onAddComment({ ...values }))} autoComplete="off">
                    <Stack>
                    <Box className={classes.timelineWrapper}>
                    <ScrollArea h={'100%'} viewportRef={viewport} type='always'>
                        <Timeline active={data?.chronology.length} bulletSize={24} lineWidth={2} m={'md'}>
                            {timeline}
                        </Timeline>
                    </ScrollArea>
                    </Box>
                    <TextInput label='Add Comment' {...form.getInputProps('remarks')} disabled={addCommentMutation.isLoading}/>
                    <Flex justify="flex-end">
                        <Button type='submit' loading={addCommentMutation.isLoading}>Submit</Button>
                    </Flex>
                    </Stack>
                    </form>
                </Grid.Col>
            </Grid>
            </Skeleton>
        </>
    )
}
