import { createStyles, Title, Text, Button, Group } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  root: {
    height: '88vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', 
  },

  label: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 120,
    lineHeight: 1,
    marginBottom: theme.spacing.xl * 1.5,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],

    [theme.fn.smallerThan('xl')]: {
      fontSize: 100,
    },

    [theme.fn.smallerThan('md')]: {
      fontSize: 60,
    },

    [theme.fn.smallerThan('sm')]: {
      fontSize: 45,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 38,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 500,
    margin: 'auto',
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl * 1.5,
  },
}));

export default function Unauthorized() {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    
        <div className={classes.root}>
            <div>
              <div className={classes.label}>Unauthorized</div>
              <Title className={classes.title}>You have found a secret place.</Title>
              <Text color="dimmed" size="lg" align="center" className={classes.description}>
                  Unfortunately, you are not able to access this page.
              </Text>
              <Group position="center" mt='lg'>
                  <Button variant="subtle" size="md" onClick={()=>{navigate('/', { replace: true })}}>
                    Take me back
                  </Button>
              </Group>
            </div>
        </div>
        
  );
}