import { useState } from 'react';
import { AppShell, Container } from '@mantine/core';
import { Outlet } from "react-router-dom";

import SideNavbar from './SideNavbar';
import TopHeader from './TopHeader';

import useAuth from '../../hooks/useAuth';

import { ROLE_ADMIN } from '../../utils/Constants';

export default function SappotAppShell(){
    const { auth } = useAuth()
    const [opened, setOpened] = useState(false);

    return (
        <>
        {auth?.role === ROLE_ADMIN ?
            <AppShell
                styles={{
                    main: { transition: "padding-left 500ms ease" }
                }}
                padding="md"
                navbarOffsetBreakpoint="sm"
                navbar={<SideNavbar setOpened={setOpened} opened={opened} />}
                header={<TopHeader setOpened={setOpened} opened={opened} />}
            >
                <Outlet />
            </AppShell>
            :
            <AppShell
                styles={{
                    main: { transition: "padding-left 500ms ease" }
                }}
                padding="md"
                navbarOffsetBreakpoint="sm"
                // navbar={<SideNavbar setOpened={setOpened} opened={opened} />}
                header={<TopHeader setOpened={setOpened} opened={opened} />}
            >
                <Container size={'lg'} p={0}>
                    <Outlet />
                </Container>
            </AppShell>
        }
        </>
    )
}
