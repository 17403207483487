import React, { useEffect } from 'react'
import {
    TextInput,
    PasswordInput,
    Checkbox,
    Paper,
    Title,
    Container,
    Group,
    Button,
  } from '@mantine/core';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from '@mantine/form'
import { useMutation } from 'react-query';
import { notifications } from '@mantine/notifications';

import useAuth from '../../hooks/useAuth';
import axios from '../../api/axios';


export default function Login() {

    const { setAuth, persist, setPersist } = useAuth()

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const togglePersist = () => {
        setPersist(prev => !prev)
    }


    useEffect(() => {
        localStorage.setItem("persist", persist)
    }, [persist])

    const loginMutation = useMutation(
        (data) => axios.post("/auth/login", data, {headers: {'Content-Type': 'multipart/form-data'}}),
        {
            onSuccess: (res) => {
                // console.log(res.data)
                let userDetails = res.data.userDetails
                let accessToken = res?.data?.token;
                setAuth({ fullname: userDetails?.fullname, uid: userDetails?.uid, company: userDetails?.company, role: userDetails?.role, accessToken})

                // setAuth({ fullname: res.data.userDetails.fullname, uid: res.data.userDetails.uid, accessToken: res.data.token, role: ROLE_ADMIN })
                navigate(from, { replace: true })
            },
            onError:(err) => {
                let errMsg = err?.response?.data?.message
                console.log(err)
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Something went wrong while connecting to the server',
                    color: 'red',
                })
            }
        }
    );

    const onSignIn = async (data) => {
        loginMutation.mutate({ email: data.email.trim(), password: data.password.trim() })
        // setAuth({ fullname: 'Thong Yew Ming', uid: 'test-uid', accessToken: 'test-token', role: ROLE_ADMIN })
        // navigate(from, { replace: true })
    }

    
    const form = useForm({
        initialValues: { email: '', password: ''},

        validate: {
            email: (value) => (value.length < 1 ? 'Please enter email' : /^\S+@\S+$/.test(value) ? null : 'Invalid Email Address'),
            password: (value) => (value.length < 1 ? 'Please enter password' : null)
        },
    });

  return (
    <div style={{display: 'table',
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%'}}>
        <div style={{display: 'table-cell', verticalAlign: 'middle'}}>

            <Container size={420} my={40}>
                <Title
                    align="center"
                    sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
                >
                    Welcome back!
                </Title>
                <Paper withBorder shadow="md" p={30} mt={30} >
                    <form onSubmit={form.onSubmit((values) => onSignIn(values))}>
                        <TextInput label="Email" placeholder="you@email.com" {...form.getInputProps('email')} />
                        <PasswordInput label="Password" placeholder="Your password" mt="md" {...form.getInputProps('password')} />
                        <Group position="apart" mt="lg">
                            <Checkbox label="Trust this device" sx={{ lineHeight: 1 }} checked={persist} onChange={togglePersist}/>
                        </Group>
                        <Button type="submit" fullWidth mt="xl" loading={loginMutation.isLoading}>
                            Sign in
                        </Button>
                    </form>
                </Paper>
            </Container>

        </div>
    </div>
  );
}
