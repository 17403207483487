import { useEffect } from 'react';
import {
    Button, TextInput, Modal, Stack, Flex, Select, Textarea,
    Divider
} from '@mantine/core';
import { useForm } from '@mantine/form';


export default function UpdateTicketStatusModal({ opened, onClose, data, onSubmit }) {

    const form = useForm({
        initialValues: { id: '', status: '', remarks: '' },

        validate: {
            status: (value) => (value.length < 1 ? 'Please select status' : null),
        },
    });

    useEffect(() => {
        if (data != null) {
            form.setValues({ id: data.id, status: data.status});
        }
    }, [data, opened]);

    return (
        <Modal
            centered
            opened={opened}
            onClose={onClose}
            title={'Update Status'}
            style={{ zIndex: 999 }}
            padding={'xl'}
        >
            <form onSubmit={form.onSubmit((values) => onSubmit({ ...values }))} autoComplete="off">
                <Stack>
                    <TextInput
                        label="User"
                        value={`${data?.fullname} (${data?.company})`}
                        readOnly
                    />
                    <TextInput
                        label="Ticket Title"
                        value={data?.title}
                        readOnly
                    />
                    <TextInput
                        label="Current Status"
                        value={data?.status}
                        readOnly
                    />
                    <Divider my="sm"/>
                    <Select
                        label="Status"
                        data={[
                            { value: 'Open', label: 'Open' },
                            // { value: 'Assigned', label: 'Assigned' },
                            { value: 'In Progress', label: 'In Progress' },
                            { value: 'Pending', label: 'Pending' },
                            { value: 'On Hold', label: 'On Hold' },
                            { value: 'Resolved', label: 'Resolved' },
                            { value: 'Closed', label: 'Closed' },
                            { value: 'Cancelled', label: 'Cancelled' },
                        ]}
                        {...form.getInputProps('status')}
                    />
                    {/* Open: The ticket has been created but has not yet been assigned or addressed.
                    Assigned: The ticket has been assigned to a specific individual or team for action.
                    In Progress: Work is actively being done to address the ticket's issue or request.
                    Pending: The ticket is awaiting some external action or information before it can proceed.
                    On Hold: The ticket is temporarily suspended or delayed, often waiting for a particular event or condition.
                    Resolved: The issue or request associated with the ticket has been addressed to the satisfaction of the requester.
                    Closed: The ticket is considered completed, and no further action is needed.
                    Cancelled: The ticket is cancelled. */}
                    <Textarea
                        label="Remarks"
                        description="*Optional"
                        minRows={4}
                        // value={data?.remarks}
                        {...form.getInputProps('remarks')}
                    />
                </Stack>
                <Flex justify="flex-start"
                    align="center"
                    direction="row"
                    wrap="wrap"
                    gap="md" mt={'xl'}>
                    <Button color='gray' sx={{ flex: 1 }} onClick={() => { onClose() }} variant='light'>
                        Cancel
                    </Button>
                    <Button type="submit" sx={{ flex: 1 }} variant='light'>
                        Update
                    </Button>
                </Flex>
            </form>
        </Modal>
    )
}