import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import { Loader } from '@mantine/core';
import useRefreshToken from "../../hooks/useRefreshToken";
import useAuth from "../../hooks/useAuth";

export default function PersistLogin() {
    const [isLoading, setIsLoading] = useState(true)
    const refresh = useRefreshToken();
    const { auth, persist } = useAuth();

    useEffect(() => {
        let isMounted = true;

        const verifyRefreshToken = async () => {
            try {
                await refresh();
            }
            catch(err){
                console.error(err)
            }
            finally{
                isMounted && setIsLoading(false)
            }
        }

        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false)

        return () => isMounted = false
    }, [])
  return (
    <>
        {!persist
            ? <Outlet />
            : isLoading
                ? <Loader size="xl" variant="dots" sx={{position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',}}/>
                : <Outlet />
        }
    </>
  )
}