import React from 'react'
import { Group, Text, Divider, Button, Anchor } from '@mantine/core'
import { useState } from 'react'
import { IconUser, IconPencil, IconArrowBack, IconTrash, IconCheck, IconExclamationCircle } from '@tabler/icons-react'
import { DataGrid } from 'mantine-data-grid';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { notifications } from '@mantine/notifications';

import { Link, useParams } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';


import CreateCategoryModal from '../../components/Category/CreateCategoryModal';
import EditCategoryModal from '../../components/Category/EditCategoryModal';
import ConfirmationModal from '../../components/General/ConfirmationModal';

export default function Category() {
    const axiosPrivate = useAxiosPrivate();
    const queryClient = useQueryClient();
    const { auth } = useAuth()
    const { uid } = useParams()

    const [openCreateModal, setOpenCreateModal] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openRestoreModal, setOpenRestoreModal] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)

    const [selectedRow, setSelectedRow] = useState(null)

    const { isFetching, isError, data, } = useQuery(
        "category",
        async ({ signal }) => (await axiosPrivate.post(`/category/read-by-uid`, { uid }, { signal })).data.category,
        {
            initialData: [],
            onSuccess: (res) => {
                console.log(res)
            },
            onError: () => {
                notifications.show({
                    id: 'error',
                    title: `Error`,
                    message: `Unable to load category`,
                    autoClose: 10000,
                    withCloseButton: true,
                })
            },
            cacheTime: 0,
        },
    );

    const createCategoryMutation = useMutation(
        (data) => axiosPrivate.post(`/category/create`, data),
        {
            onSuccess: () => {
                notifications.update({
                    id: 'create-category',
                    color: 'teal',
                    title: 'Category created',
                    message: 'The category account is successfully created.',
                    icon: <IconCheck size={16} />,
                    autoClose: 4000,
                });
                queryClient.invalidateQueries("category")
                queryClient.invalidateQueries("users-client")
            },
            onError: () => {
                notifications.update({
                    id: 'create-category',
                    color: 'red',
                    title: 'Error',
                    message: 'An error has occurred.',
                    icon: <IconExclamationCircle size={16} />,
                    autoClose: 4000,
                });
            }
        }
    );

    const editCategoryMutation = useMutation(

        (data) => axiosPrivate.post(`/category/update`, data),
        {
            onSuccess: () => {
                notifications.update({
                    id: 'edit-category',
                    color: 'teal',
                    title: 'Category updated',
                    message: 'The category is successfully updated.',
                    icon: <IconCheck size={16} />,
                    autoClose: 4000,
                });
                queryClient.invalidateQueries("category")
            },
            onError: (err) => {
                let errMsg = err?.response?.data?.message
                notifications.update({
                    id: 'edit-category',
                    color: 'red',
                    title: 'Error',
                    message: errMsg ? errMsg : 'An error has occurred.',
                    icon: <IconExclamationCircle size={16} />,
                    autoClose: 4000,
                });
            }
        }
    );

    const deleteUserMutation = useMutation(
        (id) => axiosPrivate.post('/category/delete', {id: id}),
        {
            onSuccess: () => {
                notifications.update({
                    id: 'delete-category',
                    color: 'teal',
                    title: `Category deleted`,
                    message: `The category is successfully deleted.`,
                    icon: <IconCheck size={16} />,
                    autoClose: 4000,
                });
                queryClient.invalidateQueries("category")
            },
            onError: () => {
                notifications.update({
                    id: 'delete-category',
                    color: 'red',
                    title: 'Error',
                    message: 'An error has occurred.',
                    icon: <IconExclamationCircle size={16} />,
                    autoClose: 4000,
                });
            }
        }
    );

    const restoreUserMutation = useMutation(
        (id) => axiosPrivate.post('/category/restore', {id: id}),
        {
            onSuccess: () => {
                notifications.update({
                    id: 'restore-category',
                    color: 'teal',
                    title: `Category restored`,
                    message: `The category is successfully restored.`,
                    icon: <IconCheck size={16} />,
                    autoClose: 4000,
                });
                queryClient.invalidateQueries("category")
            },
            onError: () => {
                notifications.update({
                    id: 'restore-category',
                    color: 'red',
                    title: 'Error',
                    message: 'An error has occurred.',
                    icon: <IconExclamationCircle size={16} />,
                    autoClose: 4000,
                });
            }
        }
    );

    const onCreateCategory = (data) => {
        setOpenCreateModal(false);
        notifications.show({
            id: 'create-category',
            loading: true,
            title: 'Creating Category',
            message: 'The category account is being created.',
            autoClose: false,
        })
        createCategoryMutation.mutate({ ...data })
    }

    const onEditCategory = (data) => {
        setOpenEditModal(false);
        notifications.show({
            id: 'edit-category',
            loading: true,
            title: 'Updating Category',
            message: 'The category account is being updated.',
            autoClose: false,
        })
        editCategoryMutation.mutate({ ...data })
    }

    const onDeleteUser = () => {
        setOpenDeleteModal(false)
        notifications.show({
            id: 'delete-category',
            loading: true,
            title: `Deleting Category`,
            message: `The admin account is being deleted.`,
            autoClose: false,
        })
        deleteUserMutation.mutate(selectedRow?.id)
    }

    const onRestoreUser = () => {
        setOpenRestoreModal(false)
        notifications.show({
            id: 'restore-category',
            loading: true,
            title: `Restoring Category`,
            message: `The admin account is being restored.`,
            autoClose: false,
        })
        restoreUserMutation.mutate(selectedRow?.id)
    }

    return (
        <>
            {/* Create Modal */}
            <CreateCategoryModal opened={openCreateModal} onClose={() => setOpenCreateModal(false)} onSubmit={onCreateCategory}
                isLoading={createCategoryMutation.isLoading} isSuccess={createCategoryMutation.isSuccess} isError={createCategoryMutation.isError} uid={uid}/>
            {/* Edit Modal */}
            <EditCategoryModal data={selectedRow} opened={openEditModal} onClose={() => setOpenEditModal(false)} onSubmit={onEditCategory}
                isLoading={editCategoryMutation.isLoading} isSuccess={editCategoryMutation.isSuccess} isError={editCategoryMutation.isError} />
            {/* Delete Confirmation Modal */}
            <ConfirmationModal opened={openDeleteModal} onClose={() => setOpenDeleteModal(false)} text={`Are you sure you want to delete ${selectedRow?.name}?`} onClick={onDeleteUser} />
            {/* Restore Confirmation Modal */}
            <ConfirmationModal opened={openRestoreModal} onClose={() => setOpenRestoreModal(false)} text={`Are you sure you want to restore ${selectedRow?.name}?`} onClick={onRestoreUser} />
            <Group position='apart'>
                <Group spacing="xs">
                    <Anchor fz={'xl'} fw={600} component={Link} to={'/client'}>Client</Anchor>
                    <Text size={'xl'} weight={600}>/ Category</Text>
                </Group>
                <Button disabled={isError} onClick={() => setOpenCreateModal(true)} leftIcon={<IconUser size={18} />} variant='light' color="green" size='xs'>
                    Create Category
                </Button>
            </Group>
            <Divider my="md" />
            <DataGrid
                data={data}
                loading={isFetching}
                striped
                highlightOnHover
                withGlobalFilter
                withPagination
                //withColumnFilters
                withSorting
                //withColumnResizing
                columns={[
                    {
                        header: 'No.',
                        cell: (cell) => {
                            return <Text>{cell.row.index + 1}</Text>;
                        },
                        size: 60,
                    },
                    {
                        accessorKey: 'name',
                        header: 'Name',
                        size: 150,
                    },
                    {
                        header: 'Action',
                        cell: (cell) => {
                            return <Group position="center">
                                {cell.row.original.deleted ?
                                    <>
                                        <Button onClick={() => { setSelectedRow(cell.row.original); setOpenEditModal(true) }} variant={'light'} leftIcon={<IconPencil size={18} />} size="xs" color="yellow" disabled >Edit</Button>
                                        <Button onClick={() => { setSelectedRow(cell.row.original); setOpenRestoreModal(true)}} variant={'light'} leftIcon={<IconArrowBack size={18} />} size="xs" color="lime">Restore</Button>
                                    </>
                                    :
                                    <>
                                        <Button onClick={() => { setSelectedRow(cell.row.original); setOpenEditModal(true) }} variant={'light'} leftIcon={<IconPencil size={18} />} size="xs" color="yellow" >Edit</Button>
                                        <Button disabled={auth?.uid === cell.row.original.id} onClick={() => { setSelectedRow(cell.row.original); setOpenDeleteModal(true);}} variant={'light'} leftIcon={<IconTrash size={18} />} size="xs" color="red">Delete</Button>
                                    </>
                                }
                            </Group>;
                        },
                        enableColumnFilter: false,
                        enableSorting: false,
                        enableGlobalFilter: false,
                        size: 265,
                    },
                ]}
            />
        </>
    )
}
